import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, Timestamp, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { Post } from '../components/Post';
import { TrendingUp, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosting em Alta',
    subtitle: 'Top 50 posts mais curtidos nas últimas 24 horas',
    loading: 'Selecionando os poosts com engajamento...',
    noResults: 'Nenhum post em alta encontrado',
    errors: {
      loadFailed: 'Falha ao carregar posts em alta'
    }
  },
  'en': {
    title: 'Trending Poosting',
    subtitle: 'Top 50 most liked posts in the last 24 hours',
    loading: 'Selecting posts with engagement...',
    noResults: 'No trending posts found',
    errors: {
      loadFailed: 'Failed to load trending posts'
    }
  }
};

// Maximum number of trending posts to display
const MAX_TRENDING_POSTS = 50;

export function Trending() {
  const [trendingPosts, setTrendingPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const fetchTrendingPosts = async () => {
      try {
        setLoading(true);
        
        // Calculate timestamp for 24 hours ago
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
        
        // Query posts from the last 24 hours
        const postsRef = collection(db, 'posts');
        const q = query(
          postsRef,
          where('timestamp', '>=', Timestamp.fromDate(twentyFourHoursAgo)),
          orderBy('timestamp', 'desc')
        );
        
        const snapshot = await getDocs(q);
        
        // Get posts with likes count
        const posts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date(),
          likesCount: doc.data().likes?.length || 0
        }));
        
        // Filter posts with at least one like and sort by likes count (descending)
        const filteredPosts = posts
          .filter(post => post.likesCount > 0)
          .sort((a, b) => b.likesCount - a.likesCount)
          .slice(0, MAX_TRENDING_POSTS); // Limit to exactly 50 posts
        
        setTrendingPosts(filteredPosts);
      } catch (error) {
        console.error('Error fetching trending posts:', error);
        toast.error(t('errors.loadFailed'));
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingPosts();
  }, [language]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <TrendingUp className={`h-6 w-6 ${isDarkMode ? 'text-orange-400' : 'text-orange-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className={`p-4 ${isDarkMode ? 'bg-orange-900/20' : 'bg-orange-50'} border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <p className={`text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {t('subtitle')}
          </p>
        </div>

        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-orange-400' : 'text-orange-500'}`} />
            <p className={`ml-3 ${isDarkMode ? 'text-white' : 'text-gray-700'}`}>{t('loading')}</p>
          </div>
        ) : trendingPosts.length > 0 ? (
          <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
            {trendingPosts.map((post) => (
              <Post 
                key={post.id} 
                {...post} 
                isDarkMode={isDarkMode}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <TrendingUp className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
            <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('noResults')}
            </p>
          </div>
        )}
      </main>
    </div>
  );
}